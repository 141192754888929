import React from "react"
import SEO from "../util/SEO"
import Layout from "../components/layout"

export default () => (
  <>
    <SEO title="404" />
    <Layout>
      <section className="section">
        <div className="row">
          <div className="col-xs">404</div>
        </div>
      </section>
    </Layout>
  </>
)
